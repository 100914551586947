body {
  padding: 0px;
  font-family: 'Exo', sans-serif;
  min-height: 100%;
  height: auto;
}
body::-webkit-scrollbar {
  display: none;
}

/* smooth scrolling */
html {
  scroll-behavior: initial !important;
}

.appContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    -60deg,
    #4d8ea8,
    #718a99,
    #93bfcf,
    #6096b4,
    #f7bd00
  );
  background-size: 1000%100%;
  animation: gradientBG 10s ease infinite;
  transition: transform 0.5s;
}

.appContainerDark {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    -60deg,
    #014366,
    #1f4459,
    #194150,
    #002a40,
    #f7b100
  );
  background-size: 1000%100%;
  animation: gradientBG 10s ease infinite;
  transition: transform 0.5s;
  color: white;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* FJERNER MARKERING AV TEKST */
::selection {
  background-color: transparent;
  color: inherit;
}

.contactMeBtn {
  position: fixed;
  left: -42px;
  border: 1px solid black;
  transform: rotate(270deg);
  background-color: rgba(20, 20, 20, 0.2);
  border-radius: 6px;
}

.contactMeBtn:hover {
  background-color: rgba(20, 20, 20, 0.5);
  cursor: pointer;
}

.contactMeText {
  margin: 2px;
  color: white;
}

h1 {
  font-weight: 300;
}

h3 {
  color: #eee;
  font-weight: 100;
}

h5 {
  color: #eee;
  font-weight: 300;
}

a,
button,
a:hover {
  text-decoration: none;
  color: #ddd;
}

canvas {
  touch-action: none;
}

.iphoneScreen iframe {
  width: 410px;
  height: 870px;
  border: none;
  border-radius: 70px;
  background: #000000;
}
.macScreen iframe {
  width: 1024px;
  height: 770px;
  border: none;
  border-radius: 20px;
  background: #000000;
}
@media (max-width: 510px) {
  .appContainerDark {
    min-height: 620vh;
  }
  .appContainer {
    height: 620vh;
  }
}
