.fallingTextContainer {
  width: 70%;
}

@media (max-width: 510px) {
  .fallingTextContainer {
    width: 100%;
  }
}
@media (min-width: 510px) {
  .fallingTextContainer {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}
