.reactDescriptContainer {
  position: absolute;
  top: 310px;
  right: 10%;
}

.reactDescription {
  letter-spacing: 4px;
  text-align: center;
}

.deviceBtnWebLink {
  position: relative;
  /* left: 200; */
  z-index: 5;
  width: 130px;
  height: 45px;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  animation: colorCycleWebLink 5s infinite;
  margin-bottom: 3px;
}

.deviceBtn {
  position: relative;
  left: 200;
  z-index: 5;
  width: 130px;
  height: 45px;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  animation: colorCycle 5s infinite;
  margin-bottom: 3px;
}

@keyframes colorCycleWebLink {
  0%,
  33%,
  100% {
    background-color: darkorange;
  }
  16.5%,
  66% {
    background-color: #e6c200;
  }
}
@keyframes colorCycle {
  0%,
  33%,
  100% {
    background-color: #e6c200;
  }
  16.5%,
  66% {
    background-color: darkorange;
  }
}
.deviceBtn:hover {
  width: 133px;
  height: 48px;
  margin-bottom: 0px;
}
.deviceBtnWebLink:hover {
  width: 133px;
  height: 48px;
  margin-bottom: 0px;
}

@keyframes slide {
  0% {
    transform: translateY(10%) rotate(-20deg);
    opacity: 1;
  }

  40% {
    transform: translateY(-170%) rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: translateY(-180%);
    opacity: 0;
  }
}

.scrollPointer {
  font-size: 40px;
  animation: slide 4s linear infinite;
  transform: translateY(-50%);
  position: absolute;
  top: 70%;
  z-index: 2;
}

@media (min-width: 1025px) {
  .reactDescriptContainer {
    padding-right: 50px;
  }
  .reactDescription {
    text-align: left;
  }
}

@media (min-width: 1025px) and (max-width: 1100px) {
  .reactDescriptContainer {
    right: 0%;
  }
}
@media (min-width: 1100px) and (max-width: 1200px) {
  .reactDescriptContainer {
    right: 5%;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .reactDescriptContainer {
    right: 7%;
  }
}
@media (min-width: 1300px) and (max-width: 1600px) {
  .reactDescriptContainer {
    right: 9%;
  }
}
@media (min-width: 1600px) {
  .reactDescriptContainer {
    right: 10%;
  }
}
@media (min-width: 2000px) {
  .reactDescriptContainer {
    right: 12%;
  }
}
@media (min-width: 2300px) {
  .reactDescriptContainer {
    right: 17%;
  }
}
@media (min-width: 2600px) {
  .reactDescriptContainer {
    right: 20%;
  }
}
@media (min-width: 3000px) {
  .reactDescriptContainer {
    right: 25%;
  }
}

@media (min-width: 510px) and (max-width: 1024px) {
  .reactDescription {
    text-align: center;
  }
  .deviceBtn {
    left: 50%;
    transform: translateX(-50%);
  }
  .deviceBtnWebLink {
    left: 50%;
    transform: translateX(-50%);
  }
  .canvasContainer {
    width: 80%;
    margin-top: 50px;
  }
}

@media (max-width: 1024px) {
  .reactDescriptContainer {
    top: 700px;
    left: 10%;
  }
}
@media (max-width: 510px) {
  .reactDescriptContainer {
    text-align: center;
  }
}
