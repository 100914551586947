.emailContainer {
  display: flex;
  flex-direction: column;
  width: 450px;
  border-radius: 10px;
  background-color: rgba(20, 20, 20, 0.8);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.7);
}

.confirmationContainer {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 4px 10px;
  margin-bottom: 3%;
}

.input-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.email-label {
  font-size: 18px;
  font-weight: 600;
}

.inputField {
  width: 50%;
  height: 25px;
  border-radius: 10px;
  border: none;
  padding: 0px 10px;
}
.textArea {
  width: 50%;
  height: 50px;
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
}

.sendBtn {
  align-self: flex-end;
  border: none;
  border-radius: 10px;
  padding: 10px 0px;
  width: 20%;
  margin: 20px 20px;
  background-color: rgba(250, 250, 250, 0.4);
  text-decoration: none;
}
.sendBtn:hover:not([disabled]) {
  background-color: rgba(250, 250, 250, 0.9);
}
.sendBtn:disabled {
  background-color: rgba(250, 250, 250, 0.2);
}
.exitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: rgba(250, 250, 250, 0.4);
  align-self: flex-end;
  border: none;
  margin: 15px 20px;
  padding: 0px 10px;
  border-radius: 10px;
}
.exitBtn:hover {
  background-color: rgba(250, 250, 250, 0.9);
}

@media (max-width: 510px) {
  .emailContainer {
    width: 350px;
    background-color: rgba(20, 20, 20, 0.96);
  }
  .inputField {
    width: 60%;
  }
  .textArea {
    width: 60%;
  }
}
