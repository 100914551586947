canvas {
  touch-action: none;
}

.fiberHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 511px) {
  .fiberHeaderContainer {
    margin: 0px;
    padding: 20px;
    margin-top: 80px;
    justify-content: center;
  }
}

@media (max-width: 510px) {
  .fiberHeaderContainer {
    margin-top: 80px;
    align-items: center;
  }
}
