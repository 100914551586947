.htmlScreen iframe {
  width: 1024px;
  height: 670px;
  border: none;
  border-radius: 20px;
  background-color: '#000000';
}

@media (max-width: 510px) {
  .htmlScreen {
    pointer-events: none;
  }
}
