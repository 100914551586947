.cvProfileContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 6px 5px 15px rgba(0, 0, 0, 1);
  padding-top: 100px;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: space-between;
  width: 75%;
  align-self: center;
  max-width: 1200px;
}

.mailContent {
  transition: left 0.3s ease-in-out;
}
.cvProfileImg {
  max-width: 200px;
  transition: opacity 0.5s, transform 0.5s;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid rgb(34, 49, 66);
  z-index: 2;
}

.cvProfileImg:hover {
  transform: rotate(2deg);
  transition: opacity 0.5s, transform 0.5s;
}

.cvCourses {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-bottom: 20px;
}

.cornerBox {
  position: absolute;
  z-index: 0;
  width: 160px;
  height: 170px;
  background-color: rgb(34, 49, 66);
  margin-left: -14.2px;
  margin-top: -0.5px;
}

.cvProfileImgContainer {
  display: flex;
  border-radius: 50%;
  align-self: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 25px;
  margin-top: 30px;
  z-index: 1;
}

.pointersContainer {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-bottom: 20px;
}

.pointers {
  margin: 2px 0px;
  text-align: left;
}

.columnHeaders {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  margin-bottom: 10px;
}

.leftHeader {
  width: 90%;
}

.contactContainer {
  display: flex;
  width: 80%;
  align-self: center;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-around;
}

.contactBtn {
  display: flex;
  width: 40px;
  margin-bottom: 10px;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  border-radius: 20%;
  border: none;
  cursor: pointer;
  animation: rotate 6s linear infinite;
}

.delay {
  animation-delay: 3s;
}
.cvHeaders {
  margin: 5px 0px;
  align-self: flex-start;
  letter-spacing: 4px;
  padding-left: 10px;
}

@keyframes rotate {
  0%,
  50%,
  100% {
    transform: rotate(0deg);
  }
  65%,
  75%,
  85%,
  95% {
    transform: rotate(20deg);
  }
  60%,
  70%,
  80%,
  90% {
    transform: rotate(-20deg);
  }
}

.leftRightContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* LEFT COLUMN */
.columnContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  background-color: transparent;
}

.leftC {
  width: 38%;
}

.rightC {
  width: 62%;
}
.rightCvInfo {
  padding-right: 8%;
  padding-left: 3%;
  margin-top: 270px;
  margin-bottom: 50px;
}
@media (min-width: 767px) and (max-width: 1024px) {
  .cvProfileContainer {
    width: 90%;
  }
}
@media (min-width: 511px) and (max-width: 767px) {
  .cornerBox {
    position: absolute;
    z-index: 0;
    width: 260px;
    height: 170px;
    background-color: rgb(34, 49, 66);
    margin-left: -14.2px;
    margin-top: -0.5px;
  }
  .cvProfileContainer {
    width: 90%;
    padding-top: 350px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .pointersContainer {
    margin-left: 0px;
  }
  .leftRightContainer {
    flex-direction: column;
  }
  .leftC {
    width: 90%;
    align-self: center;
  }
  .rightC {
    width: 100%;
    margin-top: -200px;
  }
  .columnHeaders {
    width: 100%;
  }
  .contactContainer {
    width: 100%;
  }
  .rightCvInfo {
    padding-right: 30px;
    padding-left: 0%;
    margin-top: 190px;
  }

  .workAndEducationHeader {
    width: 100%;
  }
  .workAndEducationContent {
    width: 100%;
  }
  .cvCourses {
    width: 90%;
    padding-left: 10px;
  }
}

@media (max-width: 510px) {
  .cvProfileContainer {
    width: 100%;
    padding-top: 350px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .pointersContainer {
    margin-left: 0px;
  }
  .leftRightContainer {
    flex-direction: column;
  }
  .leftC {
    width: 90%;
    align-self: center;
  }
  .rightC {
    width: 100%;
    margin-top: -200px;
  }
  .columnHeaders {
    width: 100%;
  }
  .contactContainer {
    width: 100%;
  }
  .rightCvInfo {
    padding-right: 30px;
    padding-left: 0%;
    margin-top: 190px;
  }

  .workAndEducationHeader {
    width: 100%;
  }
  .workAndEducationContent {
    width: 100%;
  }
  .cvCourses {
    width: 90%;
    padding-left: 10px;
  }
}

@media (min-width: 1280px) {
  .cvProfileContainer {
    max-width: 900px;
  }
}
/* @media (min-width: 1680px) {
  .cvProfileContainer {
    width: 65%;
  }
} */
