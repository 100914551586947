.toggleContainer {
  width: 30%;
  z-index: 101;
}

.toggleBtn {
  position: absolute;
  top: 5px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75px;
  height: 35px;
  border-radius: 20px;
  z-index: 10;
  background: linear-gradient(
    to right,
    rgb(229, 204, 16),
    /* transparent, */ /* transparent, */ darkorange
  );
  border: none;
  cursor: pointer;
}
.toggleBtnDark {
  position: absolute;
  top: 5px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75px;
  height: 35px;
  border-radius: 20px;
  z-index: 1;
  background: linear-gradient(
    to right,
    rgb(229, 204, 16),
    /* transparent, */ /* transparent, */ darkorange
  );
  border: none;
  cursor: pointer;
}

.iconAndBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggleIcon {
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: rgba(98, 97, 97, 0.8);
}

.modeText {
  font-size: 12px;
  font-weight: 600;
  margin: 3px;
  color: rgb(20, 20, 20);
}

@media (max-width: 510px) {
  .toggleBtn {
    top: 100px;
    left: 20px;
    background: linear-gradient(
      to right,
      rgb(34, 49, 66),
      rgb(64, 85, 109),
      transparent
    );
    padding: 15px;
  }
  .toggleBtnDark {
    top: 100px;
    left: 20px;
    background: linear-gradient(
      to right,
      transparent,
      rgb(245, 236, 170),
      rgb(251, 234, 103)
    );
  }
  .modeTextDark {
    color: white;
  }
}
