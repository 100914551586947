.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 40;
}

.text-animation {
  display: inline-block;
  animation: type 1s steps(10, end) infinite;
}

.main {
  max-width: 1200px;
  margin: 60px auto;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 250px;
}

.main div {
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  border: 16px solid #fff;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  margin: 5px;
  text-align: center;
}

.main img {
  max-width: 100%;
  height: 100%;
}

.main h4 {
  text-align: center;
  margin-bottom: -2px;
  margin-top: 2px;
}
.main p {
  text-align: left;
}

.main .short {
  grid-row: span 1;
}
.main .tall {
  grid-row: span 2;
}
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-shadow: none !important;
  border: none !important;
}
.video-element {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@keyframes type {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media (max-width: 960px) {
  .main {
    grid-template-columns: 1fr 1fr;
  }
  .main div {
    overflow: scroll;
  }
}
@media (max-width: 620px) {
  .main {
    grid-template-columns: 1fr;
    max-width: 400px;
    margin: 20px auto;
  }
}
@media (max-width: 510px) {
  .main {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  .main div {
    text-align: left;
  }
  .main img {
    max-width: 100%;
    height: auto;
  }
  .video-element {
    margin-top: -5px;
  }
}
