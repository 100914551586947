.workoutAppContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1400px;
}

.workoutImage {
  max-width: 190px;
  position: absolute;
  left: 40px;
  margin-top: 150px;
  transition: transform 0.3s;
  transform: translateY(0px) translateX(0px);
  -webkit-user-drag: none;
}

.workoutdescriptionContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 30%;
}

.workoutcodeDescription {
  /* font-size: 18px; */
  letter-spacing: 6px;
  margin: 0px 0px;
  align-self: flex-start;
}

@media (min-width: 1551px) {
  .workoutAppContainer {
    justify-content: space-between;
    align-self: center;
    width: 75%;
  }
  .workoutdescriptionContainer {
    margin-top: 160px;
    align-self: flex-end;
  }
  .workoutcodeDescription {
    padding: 20px;
  }
  /* .workoutcodeDescription {
    font-size: 22px;
  } */
  .workoutImage {
    max-width: 200px;
  }
}

@media (min-width: 1101px) and (max-width: 1550px) {
  .workoutAppContainer {
    justify-content: space-between;
  }
  .workoutdescriptionContainer {
    margin-top: 160px;
    width: 25%;
  }
  .workoutcodeDescription {
    padding: 20px 0px;
  }
  /* .workoutcodeDescription {
    font-size: 24px;
  } */
}

@media (min-width: 511px) and (max-width: 1100px) {
  .workoutAppContainer {
    flex-direction: column;
  }
  .workoutImage {
    max-width: 130px;
    margin-top: 320px;
  }
  .workoutcodeDescription {
    width: 300px;
    padding: 20px;

    /* font-weight: 600; */
  }
}
@media (min-width: 800px) and (max-width: 1100px) {
  .workoutImage {
    max-width: 190px;
    margin-top: 300px;
  }
  /* .workoutcodeDescription {
    font-size: 22px;
  } */
}
@media (max-width: 679px) {
  .workoutImage {
    margin-top: 460px;
  }
  .workoutcodeDescription {
    width: 300px;
    padding: 20px;
    text-align: center;
  }
}
