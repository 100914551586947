.navigationText {
  margin: 0;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  transition: border-bottom 0.3s ease-in-out;
  /* color: rgb(20, 20, 20); */
}

.navigationText:hover {
  /* color: rgb(38, 38, 38); */
  border-bottom: 1px solid rgb(38, 38, 38);
  transition: border-bottom 0.4s ease;
}

.nav-button {
  font-size: 16px;
  margin: 5px;
  padding: 10px 20px;
  border-radius: 15px;
  border: none;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.nav-button.active {
  background-color: rgba(255, 255, 255, 0.703);
}

.nav-button.dark {
  /* background-color: rgba(106, 106, 106, 0.703); */
  background-color: rgba(106, 106, 106, 0.6);
}

@media (max-width: 510px) {
  .navigationContainer {
    width: 100%;
    left: 0px;
    top: 0px;
  }

  .nav-button {
    font-size: 16px;
  }
  .nav-button.active {
    background-color: rgb(68, 90, 115);
  }

  .navigationText:hover {
    /* color: rgba(255, 255, 255); */
    border-bottom: 1px solid rgba(255, 255, 255);
    transition: border-bottom 0.4s ease;
  }

  .nav-button.active {
    background-color: rgba(255, 255, 255, 0.703);
  }
  .nav-button.dark {
    background-color: rgba(255, 255, 255, 0.703);
  }
}
