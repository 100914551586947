.cvHeader {
  position: absolute;
  left: 40%;
  right: 0;
  top: 0;
  overflow: hidden;
  font-size: 6.2rem;
  margin: 6px;
  border-bottom: 1px solid white;
  font-family: 'Bangers', cursive;
}

.cvHeaderLast {
  top: 152px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cvHeader {
    top: 70px;
    font-size: 65px;
    left: 48%;
  }
  .cvHeaderLast {
    top: 150px;
    left: 48%;
  }
}

@media (min-width: 511px) and (max-width: 767px) {
  .cvHeader {
    font-size: 75px;
    left: 30%;
    top: 80px;
  }
  .cvHeaderLast {
    top: 160px;
    left: 20%;
  }
}

@media (max-width: 510px) {
  .cvHeader {
    font-size: 65px;
    left: 30%;
    top: 80px;
  }
  .cvHeaderLast {
    top: 160px;
    left: 20%;
  }
}
