.phoneNavigationText {
  margin: 0;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  transition: border-bottom 0.3s ease-in-out;
  /* color: rgb(20, 20, 20); */
}

.phoneNavigationText:hover {
  /* color: rgb(38, 38, 38); */
  border-bottom: 1px solid rgb(254, 254, 254);
  transition: border-bottom 0.4s ease;
}

.phoneNav-button {
  font-size: 18px;
  margin: 5px;
  padding: 10px 20px;
  border-radius: 15px;
  border: none;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.phoneNav-button.active {
  background-color: rgba(255, 255, 255, 0.703);
}

.phoneNav-button.dark {
  background-color: rgba(106, 106, 106, 0.703);
}
