.navContainerBoth {
  width: 70%;
}
.navigationContainer {
  display: flex;
  position: fixed;
  justify-content: space-between;
  width: 95%;
  left: 2%;
  z-index: 10;
  align-items: center;
  height: 40px;
}

.navigationBar {
  display: flex;
  border-radius: 15px;
  z-index: 10;
  /* width: 340px; */
  max-width: 420px;
  justify-content: center;
  height: 40px;
  align-items: center;
}

.navigationBar.scrolled {
  transition: background-color 1s ease-in-out;
  /* background-color: rgb(238, 238, 238); */
  background-color: rgb(150, 150, 150);
}
.navigationBar.scrolledDark {
  transition: background-color 1s ease-in-out;
  background-color: rgb(141, 140, 140);
}
.navigationBar.scrolledOut {
  transition: background-color 0.5s ease-in-out;
  background-color: transparent;
}

@media (min-width: 1280px) {
  .navigationContainer {
    width: 900px;
    left: 50%;
    margin-left: -450px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .navigationContainer {
    width: 75%;
    left: 12%;
  }
}

@media (max-width: 510px) {
  .navContainerBoth {
    width: 100%;
  }
  .navigationContainer {
    width: 100%;
    left: 0px;
    top: 0px;
  }
  .navigationBar {
    width: 100%;
    left: 0px;
    position: absolute;
    border-radius: 0px;
  }

  .navigationBar.scrolledOut {
    background-color: rgb(34, 49, 66);
  }
  .navigationBar.scrolled {
    background-color: rgb(34, 49, 66);
  }

  .navigationBar.scrolledDark {
    transition: background-color 1s ease-in-out;
    background-color: rgb(34, 49, 66);
  }
}
