.downloadContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 75%;
}
.btnAndTxt {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.download-link {
  display: flex;
  justify-content: center;
  /* background-color: rgba(20, 20, 20, 0.5); */
  margin-bottom: -10px;
}

.downloadBtn {
  width: 60px;
  border-radius: 15px;
  aspect-ratio: 1.3;
  cursor: pointer;
  border: none;
  fill: white;
}

.downloadBtn:hover .downloadIcon {
  fill: darkorange /* Din ønskede farge når hovered */;
  width: 80%;
}

@media (max-width: 510px) {
  .btnAndTxt {
    justify-content: flex-start;
    align-self: center;
  }
  .downloadContainer {
    width: 100%;
  }
}
