.workoutContainerPhone {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.workoutImagePhone {
  max-width: 190px;
  position: absolute;
  /* top: 1900px; */
  top: 2500px;
  left: 50%;
  margin-left: -95px;
  margin-top: 200px;
  transition: transform 0.3s;
  transform: translateY(0px) translateX(0px);
  -webkit-user-drag: none;
}

.descriptionContainerPhone {
  display: flex;
  flex-direction: column;
  min-width: 280px;
}

.codeDescriptionPhone {
  font-size: 18px;
  letter-spacing: 6px;
  margin-bottom: 0;
}

.firstMove {
  transform: translateY(450px);
}
.secondMove {
  transform: translateY(900px);
}
.thirdMove {
  transform: translateY(1350px);
  min-width: 240px;
  left: 52%;
}

.hide {
  display: none;
}
