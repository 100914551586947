.workAndEducationHeader {
  display: flex;
  justify-content: space-between;
  width: 75%;
  text-align: start;
}
.workAndEducationContent {
  width: 75%;
}

@media (max-width: 510px) {
  .workAndEducationHeader {
    width: 90%;
    padding-left: 10px;
  }
  .workAndEducationContent {
    width: 90%;
    padding-left: 10px;
  }
}
